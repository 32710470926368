import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import EmptyMessage from "../publications/EmptyMessage"


const moment = require(`moment`)

const JobsList = (props) => {
	const { jobs } = props
  const count = jobs.length

  if (count === 0) {
    return (
    	<div className="posts">
	      <EmptyMessage
	        title="Sorry"
	        subtitle="We are not accepting new hires at this time"
	        body="Check back soon"
	      />
      </div>
    )
  } 

  return (
    <div className="posts">
    	{jobs && jobs.map( (job) => {
    		return (
    			<JobListing job={job} key={job.node.id} />
    		)
    	})}
    </div>
  )
}

const JobListing = class extends React.Component {
	state = {
		open: false
	};

	constructor(props) {
		super(props)
		
		this.handleOpenClick = this.handleOpenClick.bind(this);
	}

	handleOpenClick = () => {
		this.setState({
			open: !this.state.open
		});
	}

  render() {
    const { node } = this.props.job
    const { frontmatter, html, excerpt, fields } = node
    const { date, image } = frontmatter
    const { slug } = fields
    const fixed = getImage(image)
    const anchor = "#" + slug
    
    const parsedDate = moment(date, "YYYY-MM-D")
    const isOpen = this.state.open

    return (
      <div className="media post">
      	<a name={slug} />
        <div className="media-left">
          <GatsbyImage image={fixed} alt={frontmatter.title} />
        </div>
        <div className="media-content">
          <div className="content">
            <p className="title is-6">{frontmatter.title}</p>
            <p className="subtitle is-6 is-small">Announced {parsedDate.format("MMM Do YYYY")}</p>
            {isOpen && 
            	<div>
            		<div dangerouslySetInnerHTML={{ __html: html }} />
            		<a href={anchor} onClick={this.handleOpenClick}>&lt; Less</a>
            	</div>
          	}
            {!isOpen &&
            	<div>{excerpt} <a href={anchor} onClick={this.handleOpenClick}>More &gt;</a></div>
          	}
          </div>
        </div>
      </div>
    );
  }
}

export default JobsList