import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import ChildPage from "../components/layout/page"
import Section  from "../components/layout/section"
import { FixedContainer } from "../components/layout/container"
import JobsList from "../components/jobs/JobListing"

const moment = require(`moment`)
const config = require("../../content/data/SiteConfig")

const JobsPage = ({data}) => {
  const { allMarkdownRemark, header } = data
  const headerImage = header.publicURL
  
  const jobs = allMarkdownRemark.edges

  const parallax = {
    image: headerImage,
    strength: 100
  }

  const breadcrumbs = [
    { "key": "home", "title": "Home", "path": "/" },
    { "key": "jobs", "title": "Jobs", "path": "/jobs" }
  ]

  return (
    <ChildPage 
      title="Current Openings" 
      subtitle="Join Our Team!"
      breadcrumbs={breadcrumbs}
      parallax={parallax}
    >
      <Section>
        <FixedContainer>
          <h4 className="title is-4">Available Positions</h4>
          <JobsList jobs={jobs} />
        </FixedContainer>
      </Section>
    </ChildPage>
  );
}

export default JobsPage

export const pageQuery = graphql`query JobsPage {
  header: file(relativePath: {eq: "server_stock.jpg"}) {
    publicURL
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  allMarkdownRemark(
    filter: {
      fields: {
        type: {eq: "jobs"},
        status: {eq: "open" }
      }
    }
    sort: {order: DESC, fields: frontmatter___date}
  ) {
    edges {
      node {
        id
        excerpt
        html
        frontmatter {
          title
          date
          image {
            childImageSharp {
              gatsbyImageData(width: 200, layout: FIXED)
            }
          }
        }
        fields {
          slug
          type
          date
          status
        }
      }
    }
  }
}
`