import React from "react"

const EmptyMessage = ({ title, subtitle, body }) => (
  <div className="columns">
    <div className="column is-10 is-offset-1">
      {title && (
        <h3 className="has-text-centered title is-3">
          {title}
        </h3>
      )}
      {subtitle && (
        <h5 className="has-text-centered subtitle is-5">
          {subtitle}
        </h5>
      )}
      {body && (
        <p className="has-text-centered">{body}</p>
      )}
    </div>
  </div>
)

export default EmptyMessage